import { Stack, Box, Typography, Grid, useTheme } from "@mui/material";
import { selectBrands } from "../../../app/features/brand/brand";
import { useSelector } from "react-redux";
import FindBrandLogo from "../../../utils/data/FindBrandLogo";
import { useRefinementList } from "react-instantsearch";

const CustomRefinementList = (props) => {
  //SECTION - algolia related
  const { items, refine } = useRefinementList(props);

  //SECTION - general
  const theme = useTheme();

  //SECTION - useSelector
  const brands = useSelector(selectBrands);

  return (
    <>
      <Stack
        direction={"row"}
        paddingX={theme.spacing(2)}
        sx={{
          overflowX: "scroll",
          gap: theme.spacing(2),
        }}
      >
        {items.map((item) => {
          return (
            <Box
              sx={{
                minWidth: "110px",
                border: `1px solid`,
                borderColor: item.isRefined
                  ? theme.palette.primary.main
                  : theme.palette.gray.g200,
                backgroundColor: theme.palette.white,
                borderRadius: "10px",
                color: item.isRefined ? theme.palette.secondary.main : "gray",
              }}
              key={item.label}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                padding={"0.5rem"}
              >
                <Grid
                  container
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <img
                    style={{
                      width: "45px",
                      height: "40px",
                      objectFit: "contain",
                    }}
                    src={FindBrandLogo(brands, item.label)}
                    alt={item.label}
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.white,
                      padding: "3px",
                      minWidth: "2rem",
                      fontSize: "0.8rem",
                      borderRadius: "5px",
                      fontWeight: theme.typography.bold,
                    }}
                  >
                    {item.count}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </>
  );
};

export default CustomRefinementList;

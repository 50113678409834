import { useEffect, useState } from "react";

const Smartlook = () => {
  const smartlookKey = process.env.REACT_APP_SMARTLOOK_KEY;

  useEffect(() => {
    const sessionStarted = localStorage.getItem("smartlookSessionStarted");
    if (!sessionStarted) {
      (function (d) {
        var o = (window.smartlook = function () {
            o.api.push(arguments);
          }),
          h = d.getElementsByTagName("head")[0];
        var c = d.createElement("script");
        // eslint-disable-next-line
        o.api = new Array();
        c.async = true;
        c.type = "text/javascript";
        c.charset = "utf-8";
        c.src = "https://web-sdk.smartlook.com/recorder.js";
        h.appendChild(c);
      })(document);
      window.smartlook("init", smartlookKey, {
        region: "eu",
        advancedNetwork: {
          allowedUrls: [/.*/],
          websockets: true,
        },
      });
      window.smartlook("record", {
        forms: true,
        numbers: true,
        emails: false,
        ips: true,
      });
      // Mark the session as started
      localStorage.setItem("smartlookSessionStarted", true);
    }
  }, [smartlookKey]);

  return null;
};

export const useSmartlook = () => {
  const [alreadySetUserAttributes, setAlreadySetUserAttributes] =
    useState(false);

  const setUserAttributes = (userId, attributes) => {
    if (window.smartlook) {
      window.smartlook("identify", userId, attributes);
      setAlreadySetUserAttributes(true);
    }
  };

  return { setUserAttributes, alreadySetUserAttributes };
};

export default Smartlook;

import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import ButtonComp from "../../../../components/common/ButtonComp";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@mui/material";
import { useState } from "react";
import { GiPartyPopper } from "react-icons/gi";
import { Stack } from "@mui/system";
import { jwtDecode } from "jwt-decode";

const RegisterModal = ({ open, handleClose }) => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();

  //SECTION - useSatate
  const [progress, setProgress] = useState(0);

  //SECTION - useEffects
  useEffect(() => {
    let timer;

    if (open) {
      setProgress(0);
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = 5;
          const newProgress = oldProgress + diff;
          if (newProgress >= 110) {
            clearInterval(timer);
            handleClose();
            return 120;
          }
          return newProgress;
        });
      }, 300);
    } else {
      setProgress(0);
    }

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          textAlign: "center",
          width: { xs: "90%", sm: "700px" },
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "25px",
          paddingTop: "2rem",
        }}
        gap={"1rem"}
      >
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            padding: "1.5rem",
            borderRadius: "100px",
            boxShadow: `4px 4px 4px ${theme.palette.secondary.main},-4px -4px 4px ${theme.palette.secondary.main}`,
          }}
        >
          <GiPartyPopper
            fontSize={"8rem"}
            color={theme.palette.secondary.main}
          />
        </Stack>
        <Typography
          fontWeight={theme.typography.bold}
          fontSize={{ xs: "1.5rem", sm: "2rem" }}
        >
          {t("wallet.promotion.thanksForRegistering")}
        </Typography>
        <Typography fontSize={"1.5rem"}>
          {t("wallet.promotion.nextStepExplanation", {
            phone:
              jwtDecode(localStorage.getItem("watchTowerAccessToken"))?.user
                ?.phone || "-",
          })}
        </Typography>

        <Stack sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={progress}
            sx={{ width: "100%" }}
          />
          <ButtonComp
            content={t("wallet.promotion.acceptRegistering")}
            onClick={() => {
              handleClose();
            }}
            fullWidth
            color="secondary"
            sx={{
              height: "3rem",
              fontSize: { xs: "1rem", sm: "1.6rem" },
              fontWeight: theme.typography.bold,
              borderRadius: "0px 0px 15px 15px",
            }}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RegisterModal;

import { InputAdornment, OutlinedInput, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import React from "react";

const StyledInput = ({
  title,
  icon,
  value,
  onChange,
  placeholder,
  onKeyPress,
}) => {
  //SECTION - general
  const theme = useTheme();
  return (
    <Stack sx={{ width: "100%" }}>
      <Typography
        variant={"body2"}
        color={theme.palette.gray.g600}
        fontWeight={"bold"}
        paddingBottom={"0.25rem"}
      >
        {title}
      </Typography>
      <OutlinedInput
        color="primary"
        sx={{
          borderRadius: "10px",
          height: "40px",
          backgroundColor: "white",
          width: "100%",
          px: "1rem",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.main, // Focused border color
          },
        }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        endAdornment={<InputAdornment position="end">{icon}</InputAdornment>}
      />
    </Stack>
  );
};

export default StyledInput;

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchOffers } from "../../app/features/offer/offer";
import { useEffect, useState } from "react";
import {
  selectOffers,
  currentlySelectedOffer,
  currentlySelectedOfferId,
  getOffersStatus,
  getSpecificOfferStatus,
  selectOffer,
  fetchSpecificOffer,
} from "../../app/features/offer/offer";
import { fetchBrands } from "../../app/features/brand/brand";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { TbPackageOff } from "react-icons/tb";
import { BiError } from "react-icons/bi";
import OfferCard from "./OfferCard";
import IsEmpty from "../../utils/validation/IsEmpty";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import OfferBottomDrawer from "./OfferBottomDrawer.js";
import OfferDetails from "./OfferDetails";
import { fetchCartItems } from "../../app/features/cart/cart";
import { InstantSearch, Configure } from "react-instantsearch";

const Offers = () => {
  //ANCHOR - Algolia keys
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_PUBLIC_SEARCH_API_KEY
  );

  //SECTION - general
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("semiMd"));

  //SECTION - useState
  const [open, setOpen] = useState(false);

  //SECTION - useSelector
  const offers = useSelector(selectOffers);
  const currentOffer = useSelector(currentlySelectedOffer);
  const selectedOfferId = useSelector(currentlySelectedOfferId);
  const offerLoadingStatus = useSelector(getOffersStatus);
  const specificOfferStatus = useSelector(getSpecificOfferStatus);

  //SECTION - functions
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (offerId) => {
    dispatch(fetchSpecificOffer(offerId));
    dispatch(selectOffer(offerId));
    if (isSmallScreen) {
      return handleOpen();
    } else {
      return undefined;
    }
  };

  //SECTION - useEffect
  useEffect(() => {
    dispatch(fetchOffers());
    dispatch(fetchBrands());
    dispatch(fetchCartItems());
  }, [dispatch]);

  useEffect(() => {
    //display the first order details by default only at desktop when the user enters orders page for first time
    if (offers) {
      if (offers.length > 0 && !isSmallScreen) {
        if (IsEmpty(selectedOfferId)) {
          dispatch(fetchSpecificOffer(offers[0].id));
          dispatch(selectOffer(offers[0].id));
        }
      }
    }
  }, [offers, dispatch, isSmallScreen, selectedOfferId]);

  useEffect(() => {
    if (offers) {
      if (offers.length > 0 && isSmallScreen) {
        if (!IsEmpty(selectedOfferId)) {
          handleOpen();
        }
      }
    }
  }, [isSmallScreen, offers, selectedOfferId]);

  return (
    <>
      {offerLoadingStatus === "failed" ? (
        <Grid
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height={"80vh"}
        >
          <BiError
            color={theme.palette.error.main}
            fontSize={theme.spacing(30)}
          />
          <Typography variant="h6" fontWeight={theme.typography.bold}>
            {t("offers.fetchOffersError")}
          </Typography>
        </Grid>
      ) : offers ? (
        offers.length > 0 ? (
          <Grid container padding={theme.spacing(1)}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(8)}
            >
              <Grid
                container
                top={0}
                xs={12}
                semiMd={4.3}
                xxl={3.4}
                paddingX={isSmallScreen && theme.spacing(1)}
                height="fit-content"
                maxHeight="85vh"
                sx={{ overflowY: "auto" }}
                gap={theme.spacing(1)}
              >
                {offers.map((offer) => {
                  return (
                    <OfferCard
                      key={offer.id}
                      offer={offer}
                      handleSubmit={handleSubmit}
                    />
                  );
                })}
              </Grid>

              {specificOfferStatus === "succeeded"
                ? currentOffer && (
                    <>
                      <InstantSearch
                        indexName={process.env.REACT_APP_ALGOLIA_INDEX}
                        searchClient={searchClient}
                      >
                        {!isSmallScreen ? (
                          <OfferDetails selectedOfferId={selectedOfferId} />
                        ) : (
                          <OfferBottomDrawer
                            selectedOfferId={selectedOfferId}
                            selectedOffer={selectOffer}
                            open={open}
                            handleClose={handleClose}
                            handleOpen={handleOpen}
                          />
                        )}

                        <Configure filters={"offer_id:" + selectedOfferId} />
                      </InstantSearch>
                    </>
                  )
                : !isSmallScreen &&
                  (specificOfferStatus !== "failed" ? (
                    <Grid
                      semiMd={7.5}
                      xxl={8.5}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "80vh" }}
                    >
                      <CircularProgress size={100} />
                    </Grid>
                  ) : (
                    <Grid
                      semiMd={7.5}
                      xxl={8.5}
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <BiError
                        color={theme.palette.error.main}
                        fontSize={theme.spacing(30)}
                      />
                      <Typography
                        variant="h6"
                        fontWeight={theme.typography.bold}
                      >
                        {t("offers.fetchOfferError")}
                      </Typography>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        ) : (
          <Box p={theme.spacing(3)}>
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"80vh"}
            >
              <TbPackageOff
                fontSize={theme.spacing(30)}
                color={theme.palette.primary.main}
              />
              <Typography fontWeight={theme.typography.bold} variant="h6">
                {t("offers.empty")}
              </Typography>
            </Grid>
          </Box>
        )
      ) : (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          onClick={handleClose}
        >
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            gap={theme.spacing(2)}
          >
            <CircularProgress size={isSmallScreen ? 100 : 250} />
            <Typography fontSize={"1.5rem"}>{t("offers.loading")}</Typography>
          </Grid>
        </Backdrop>
      )}
    </>
  );
};

export default Offers;

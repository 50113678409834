import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import autboiaLogo from "../../../assets/images/logos/autobia.png";
import autboiaLogoAr from "../../../assets/images/logos/arlogo.png";
import { Divider, Stack } from "@mui/material";
import NavbarDrawer from "./NavbarDrawer";
import NavbarCart from "./NavbarCart";
import NavbarProfile from "./NavbarProfile";
import NavbarLang from "./NavbarLang";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../../utils/api/Authentication";

const Navbar = () => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: theme.palette.white,
        borderBottom: `1px solid ${theme.palette.gray.g500}`,
        boxShadow: theme.shadows[0],
      }}
    >
      <Stack
        direction={"row"}
        mx={{ xs: "1rem", sm: "1.5rem", md: "1.5rem" }}
        my={"0.8rem"}
        sx={{
          "@media (min-width: 740px)": {
            display: "block",
          },
        }}
        justifyContent={{ xs: "space-between" }}
        alignItems={"center"}
      >
        <Box>{isAuthenticated() && <NavbarDrawer />}</Box>

        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            alignItems={"center"}
            direction={"row"}
            gap={{ xs: theme.spacing(2), md: theme.spacing(4) }}
          >
            <Box sx={{ width: "8rem" }}>
              {localStorage.getItem("lng") === "ar" ? (
                <img
                  src={autboiaLogoAr}
                  alt="autobia-logo-ar"
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              ) : (
                <img
                  src={autboiaLogo}
                  alt="autobia-logo"
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              )}
            </Box>
            {isAuthenticated() && (
              <Box
                sx={{
                  display: { xs: "none" },
                  "@media (min-width: 740px)": {
                    display: "flex",
                  },
                  gap: theme.spacing(2),
                }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color={
                    location.pathname === "/orders"
                      ? theme.palette.primary.main
                      : theme.palette.tertiary.main
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/orders")}
                >
                  {t("navbar.orders")}
                </Typography>
                <Typography
                  variant="h6"
                  noWrap
                  color={
                    location.pathname === "/offers"
                      ? theme.palette.primary.main
                      : theme.palette.tertiary.main
                  }
                  component="div"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/offers")}
                >
                  {t("navbar.offers")}
                </Typography>

                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/installment-services")}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    color={theme.palette.tertiary.main}
                    component="div"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {t("navbar.installmentService")}
                  </Typography>
                </Box>
              </Box>
            )}
          </Stack>

          <Box
            sx={{
              display: { xs: "none" },
              "@media (min-width: 740px)": {
                display: "flex",
              },
              gap: "0.4rem",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", semiLg: "flex" },
              }}
            >
              <NavbarLang />
            </Box>

            {isAuthenticated() && (
              <>
                <NavbarCart />
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: theme.spacing(2) }}
                />
                <NavbarProfile />
              </>
            )}
          </Box>
        </Stack>

        <Box
          sx={{
            display: { xs: "flex" },
            "@media (min-width: 740px)": {
              display: "none",
            },
          }}
        >
          {isAuthenticated() && <NavbarCart />}
          {(location.pathname === "/otp" || location.pathname === "/login") && (
            <NavbarLang />
          )}
        </Box>
      </Stack>
    </AppBar>
  );
};

export default Navbar;

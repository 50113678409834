import * as React from "react";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import IsEmpty from "../../utils/validation/IsEmpty";
import {
  getStatus,
  getMessage,
  getShow,
  closeSnackbar,
  getErrorList,
  getErrorCode,
} from "../../app/features/error/error";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = () => {
  //SECTION - general
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  //SECTION - useSelector
  const status = useSelector(getStatus);
  const message = useSelector(getMessage);
  const errorList = useSelector(getErrorList);
  const show = useSelector(getShow);
  const errorCode = useSelector(getErrorCode);

  //SECTION - functions
  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  const displayMessage = () => {
    let messageContent = "";
    if (errorCode === "usernameOrPasswordNotCorrect") {
      messageContent += t("errorSnackbar.invalidPhone") + "\n";
    } else {
      messageContent += errorCode + "\n";
    }
    if (!IsEmpty(message) && !IsEmpty(errorList)) {
      messageContent = message;
      Object.keys(errorList).forEach((errorType) => {
        errorList[errorType].forEach((error) => {
          messageContent += "\n" + error;
        });
      });
    } else if (!IsEmpty(message)) {
      return message;
    } else if (!IsEmpty(errorList)) {
      Object.keys(errorList).forEach((errorType) => {
        errorList[errorType].forEach((error) => {
          messageContent += "\n" + error;
        });
      });
    }
    return messageContent;
  };
  return (
    <Snackbar open={show} autoHideDuration={2000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={status ? status.toLowerCase() : ""}
        sx={{
          backgroundColor:
            status === "success" ? theme.palette.success.main : "auto",
        }}
      >
        <Typography sx={{ whiteSpace: "pre-line" }}>
          {displayMessage()}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;

import { Stack, useMediaQuery, useTheme } from "@mui/system";
import React, { useState } from "react";
import En_mobile from "../../assets/images/BNPL/En_mobile.jpg";
import Ar_mobile from "../../assets/images/BNPL/Ar_mobile.jpg";
import En_web from "../../assets/images/BNPL/En_web_stretched.jpg";
import Ar_web from "../../assets/images/BNPL/Ar_web_stretched.jpg";
import StepCard from "./components/cards/StepCard";
import CustomDivider from "../../components/common/CustomDivider";
import DetailedStepCard from "./components/cards/DetailedStepCard";
import { Grid } from "@mui/material";
import StyledButton from "./components/StyledButton";
import RegisterModal from "./components/modals/RegisterModal";
import { useDispatch } from "react-redux";
import { registerInterest } from "../../app/features/wallet/wallet";
import { notifyWithError } from "../../app/features/error/error";
import arText from "../../assets/images/BNPL/arText.svg";
import enText from "../../assets/images/BNPL/arText.svg";
import { usePromotionData } from "./hooks/usePromotionData";
import { useTranslation } from "react-i18next";

const PromotionPage = () => {
  //SECTION - general
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { steps, advantages } = usePromotionData();

  //SECTION - functions
  const handleRegisterInterest = () => {
    dispatch(registerInterest()).then((payload) => {
      if (payload.type === "wallet/registerInterest/fulfilled") {
        setOpen(true);
      } else {
        dispatch(
          notifyWithError({
            message: t("errorCodes.generalErrorTryAgain"),
            type: "error",
          })
        );
      }
    });
  };

  return (
    <Stack direction={"column"} gap={"1.5rem"} justifyContent={"center"}>
      <RegisterModal open={open} handleClose={() => setOpen(false)} />
      <Stack
        sx={{
          position: "relative",
        }}
      >
        <img
          src={
            isSmallScreen
              ? i18n.language === "ar"
                ? Ar_mobile
                : En_mobile
              : i18n.language === "ar"
              ? Ar_web
              : En_web
          }
          style={{
            width: "100%",
            aspectRatio: "16:9",
          }}
          alt="Promotion"
        />
        {!isSmallScreen && (
          <img
            src={i18n.language === "ar" ? arText : enText}
            alt="promotion text"
            style={{
              position: "absolute",
              width: "20%",
              bottom: "100px",
              left: i18n.language === "ar" ? "12%" : "auto",
              right: i18n.language === "en" ? "12%" : "auto",
            }}
          />
        )}
        <StyledButton
          stackStyles={{
            marginTop: "2rem",
            marginX: "auto",
          }}
          buttonStyles={{
            boxShadow: `-5px 5px 8px ${theme.palette.gray.g400}`,
          }}
          onClick={handleRegisterInterest}
        />
      </Stack>

      <Stack
        sx={{ width: "80%", margin: "auto", maxWidth: "1200px" }}
        justifyContent={"center"}
        direction={"column"}
        gap={"2rem"}
      >
        <CustomDivider text={t("wallet.promotion.easySteps")} />
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"3rem"}
        >
          {steps.map((step, index) => (
            <StepCard
              key={index}
              title={step.title}
              text={step.text}
              icon={step.icon}
              delay={index * 0.3}
            />
          ))}
        </Stack>
        <CustomDivider text={t("wallet.promotion.manyAdvantages")} />
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          sx={{
            marginBottom: "1.5rem",
            position: !isSmallScreen ? "relative" : "auto",
          }}
        >
          {advantages.map((advantage, index) => (
            <DetailedStepCard
              key={index}
              title={advantage.title}
              text={advantage.text}
              icon={advantage.icon}
              number={advantage.number}
              top={advantage.top}
              delay={index * 0.3}
            />
          ))}
          <StyledButton
            stackStyles={
              !isSmallScreen
                ? {
                    position: "absolute",
                    bottom: "2.5rem",
                    left: i18n.language === "ar" ? "2rem" : "auto",
                    right: i18n.language === "en" ? "2rem" : "auto",
                  }
                : {
                    marginY: "2rem",
                    marginX: "auto",
                  }
            }
            buttonStyles={
              !isSmallScreen
                ? {
                    position: "absolute",
                    boxShadow: "-4px 4px 8px #fff",
                  }
                : { boxShadow: `-5px 5px 8px ${theme.palette.gray.g400}` }
            }
            onClick={handleRegisterInterest}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default PromotionPage;

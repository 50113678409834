import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { Button, Grid, Input, Tooltip, Typography } from "@mui/material";
import { IoTrashOutline } from "react-icons/io5";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ButtonComp from "../../components/common/ButtonComp";
import ValidateInputOnlyNum from "../../utils/validation/ValidateInputOnlyNum";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectBrands } from "../../app/features/brand/brand";
import FindBrandLogo from "../../utils/data/FindBrandLogo";
import isEllipsisActive from "../../utils/Helpers/IsEllipsisActive";
import { digitsArToEn } from "@persian-tools/persian-tools";
import WillBePricedWrapper from "../../components/common/WillBePricedWrapper";

const CartItem = ({ eachItem, setAllItems, deleteItem }) => {
  //SECTION - GENERAL
  const theme = useTheme();
  const { t } = useTranslation();
  const currentLang = localStorage.getItem("lng");

  //SECTION - useState
  const [quantity, setQuantity] = useState(eachItem.items_detail.quantity);
  const [item, setItem] = useState({});
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);

  //SECTION - useRefs
  const descTextRef = useRef();

  //SECTION - Selectors
  const brands = useSelector(selectBrands);

  //SECTION - useEffects
  /* it is used to update the state of the `allItems` array whenever the `item` state changes.
    MORE IMPORTANTLY it debounces the actions of adding/deleting item for 1sec then sets item
    in setAllItem state in parent (CartTable), then parent has useEffect which calls the API */
  useEffect(() => {
    const getData = setTimeout(() => {
      if (Object.keys(item).length !== 0) {
        setAllItems((prevItems) => {
          const existingItem = prevItems.find(
            (prevItem) => prevItem.id === item.id
          );

          if (existingItem) {
            // Item already exists, update the quantity
            const newQuantity = item.quantity >= 0 ? item.quantity : 0;

            return prevItems.map((prevItem) => {
              if (prevItem.id === item.id) {
                return { ...prevItem, quantity: newQuantity };
              } else {
                return prevItem;
              }
            });
          } else {
            // Item does not exist, add it to the array
            return [...prevItems, item];
          }
        });
      }
    }, 1000);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (isEllipsisActive(descTextRef.current)) {
      setIsEllipsis(true);
      return;
    }
    setIsEllipsis(false);
  }, [isEllipsis]);

  const handleChange = (e) => {
    let value = "";
    if (e.target.value === "") {
      value = 1;
    } else if (isNaN(e.target.value)) {
      e.preventDefault();
      value = 1;
    } else {
      value = parseInt(digitsArToEn(e.target.value.toString()));
    }
    if (parseInt(digitsArToEn(value.toString())) > 999999) {
      setQuantity((prev) => prev);
    } else {
      if (value !== quantity) {
        setQuantity(value);
        setItem({
          id: eachItem.id,
          quantity: value,
        });
      }
    }
  };
  return (
    <Grid
      key={eachItem.id}
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        border: `2px solid ${
          eachItem.items_detail.selling_price !== 0
            ? theme.palette.gray.g200
            : theme.palette.yellow.main
        }`,
        maxHeight: "70px",
        background:
          eachItem.items_detail.selling_price === 0 &&
          theme.palette.yellow.light,
        borderRadius: "0.8rem",
        padding: "0.5rem",
        "&:hover": {
          border: `2px solid ${theme.palette.secondary.main} `,
          backgroundColor: ` ${theme.palette.gray.g25}`,
        },
      }}
    >
      <Grid
        xs={1.5}
        sm={1}
        semiMd={1.3}
        md={1.3}
        lg={1}
        container
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={theme.spacing(0.5)}
        direction={"column"}
      >
        <img
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
          }}
          src={FindBrandLogo(brands, eachItem.item.manufacturer_id)}
          alt={eachItem.item.name_en}
        />
        <Typography
          fontSize={{
            xs: "0.5rem",
            sm: "0.7rem",
            semiMd: "0.6rem",
            lg: "0.8rem",
          }}
          textAlign={"center"}
          sx={{
            inlineSize: "100%",
            overflowWrap: "break-word",
            color: theme.palette.gray.g500,
            fontWeight: theme.typography.bold,
            whiteSpace: "nowrap",
          }}
        >
          {currentLang === "ar"
            ? eachItem.brand.name_ar
            : eachItem.brand.name_en}
        </Typography>
      </Grid>
      <Grid
        xs={3.5}
        sm={3.5}
        semiMd={3.7}
        md={3.7}
        lg={4}
        sx={{
          paddingX: { xs: "0.5rem", sm: "1rem", semiMd: "0.7rem" },
        }}
        direction={"column"}
      >
        <Typography
          fontWeight={theme.typography.bold}
          fontSize={{
            xs: "0.8rem",
            semiSm: "0.8rem",
            sm: "0.9rem",
            semiMd: "0.7",
            md: "1rem",
            whiteSpace: "nowrap",
          }}
        >
          {eachItem.item.part_number}
        </Typography>
        <Tooltip
          title={isEllipsis ? eachItem.item.description : ""}
          open={openToolTip}
          onClick={() => setOpenToolTip(true)}
          onClose={() => setOpenToolTip(false)}
          onMouseEnter={() => setOpenToolTip(true)}
          onMouseLeave={() => setOpenToolTip(false)}
        >
          <Typography
            ref={descTextRef}
            fontWeight={theme.typography.bold}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            fontSize={{
              xs: "0.7rem",
              semiSm: "0.8rem",
              semiMd: "0.7",
              sm: "0.9rem",
            }}
            color="secondary.main"
          >
            {eachItem.item.description}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        xs={2}
        sm={2.2}
        semiMd={1.7}
        md={2.2}
        lg={2.2}
      >
        <WillBePricedWrapper
          Child={() => (
            <Typography
              fontSize={{
                xs: "0.7rem",
                semiSm: "0.8rem",
                semiMd: "0.8",
                sm: "0.9rem",
              }}
              color={
                eachItem.items_detail.selling_price !== 0
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
              }
              fontWeight={theme.typography.bold}
            >
              {eachItem.items_detail.selling_price === 0
                ? t("cart.willBePriced")
                : `${eachItem.items_detail.selling_price} ${t("currency.SAR")}`}
            </Typography>
          )}
          id={eachItem.item.part_number}
          selling_price={
            eachItem.items_detail.selling_price === 0
              ? t("cart.willBePriced")
              : `${eachItem.items_detail.selling_price} ${t("currency.SAR")}`
          }
        />
      </Grid>
      <Grid
        container
        xs={5}
        sm={4.3}
        semiMd={4.3}
        md={3.8}
        lg={3.8}
        alignContent={"right"}
        justifyContent={"flex-end"}
        gap={theme.spacing(0.5)}
      >
        <Button
          color="tertiary"
          onClick={() => {
            if (quantity > 0) {
              setQuantity((prevQuantity) => prevQuantity - 1);
              setItem({
                id: eachItem.id,
                quantity: quantity - 1,
              });
            }
          }}
          sx={{
            padding: "0px",
            minWidth: "initial",
            borderRadius: "0.5rem",
            border: `1px solid ${theme.palette.gray.g200}`,
            "&:hover": {
              backgroundColor: theme.palette.gray.g100,
              border: `1px solid ${theme.palette.gray.g400}`,
            },
            backgroundColor: theme.palette.gray.g50,
            width: { xs: "fit-contet", md: "40px" },
            height: "40px",
          }}
        >
          <RemoveIcon sx={{ color: "black" }} />
        </Button>
        <Input
          value={quantity}
          disableUnderline
          inputProps={{
            style: {
              textAlign: "center",
              padding: "0px",
              height: "40px",
            },
            onKeyPress: ValidateInputOnlyNum, // only enter numbers, no chars
          }}
          sx={{
            maxWidth: "4rem",
            fontWeight: theme.typography.bold,
            border: `1px solid ${theme.palette.gray.g200}`,
            borderRadius: "0.5rem",
            backgroundColor: theme.palette.gray.g50,
            width: { xs: "48px", semiSm: "64px", md: "60px" },
            height: "40px",
            padding: "0px",
          }}
          onClick={(e) => e.target.select()}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <Button
          disabled={quantity === "999999" || quantity === 999999}
          onClick={() => {
            setQuantity((prevQuantity) => prevQuantity + 1);
            setItem({ id: eachItem.id, quantity: quantity + 1 });
          }}
          color="tertiary"
          sx={{
            padding: "0px",
            minWidth: "initial",
            borderRadius: "0.5rem",
            border: `1px solid ${theme.palette.gray.g200}`,
            "&:hover": {
              backgroundColor: theme.palette.gray.g100,
              border: `1px solid ${theme.palette.gray.g400}`,
            },
            backgroundColor: theme.palette.gray.g50,
            width: { xs: "fit-contet", md: "40px" },
            height: "40px",
            opacity: (quantity === "999999" || quantity === 999999) && 0.5,
          }}
        >
          <AddIcon sx={{ color: "black" }} />
        </Button>
      </Grid>
      <Grid
        sm={1}
        semiMd={1}
        md={1}
        container
        item
        display={{ xs: "none", sm: "inherit" }}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ButtonComp
          variant="text"
          color={"error"}
          onClick={() => {
            /* we avoid setting doing  `setItem({ id: eachItem.id, quantity: 0 })`
                because we dont need to have a debounce on straight deletion 
                Hence i did a straight call function no delay/debounce */
            deleteItem({ id: eachItem.id, quantity: 0 });
          }}
          content={<IoTrashOutline fontSize={"1.5rem"} />}
          sx={{
            padding: theme.spacing(1),
            minWidth: "initial",
            borderRadius: "50%",
            width: { xs: "fit-contet", md: "40px" },
            height: { xs: "fit-content", md: "40px" },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CartItem;

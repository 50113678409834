import { useEffect, useState } from "react";
import {
  useTheme,
  Button,
  Typography,
  Grid,
  Input,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ValidateInputOnlyNum from "../../../utils/validation/ValidateInputOnlyNum";
import { selectBrands } from "../../../app/features/brand/brand";
import FindBrandLogo from "../../../utils/data/FindBrandLogo";
import { getCartItems } from "../../../app/features/cart/cart";
import { digitsArToEn } from "@persian-tools/persian-tools";
import WillBePricedWrapper from "../../../components/common/WillBePricedWrapper";

const ItemHit = ({ hit, setAllItems }) => {
  //SECTION - GENERAL
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //SECTION - useState
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [openToolTip, setOpenToolTip] = useState(false);

  //SECTION - Selectors
  const brands = useSelector(selectBrands);
  const cartItems = useSelector(getCartItems);

  //SECTION - useEffects
  useEffect(() => {
    const getData = setTimeout(() => {
      if (Object.keys(item).length !== 0) {
        setAllItems((prevItems) => {
          const existingItem = prevItems.find(
            (prevItem) => prevItem.id === item.id
          );

          if (existingItem) {
            // Item already exists, update the quantity
            const newQuantity = item.quantity >= 0 ? item.quantity : 0;

            return prevItems.map((prevItem) => {
              if (prevItem.id === item.id) {
                return { ...prevItem, quantity: newQuantity };
              } else {
                return prevItem;
              }
            });
          } else {
            // Item does not exist, add it to the array
            return [...prevItems, item];
          }
        });
      }
    }, 300);

    return () => clearTimeout(getData);
  }, [item, setAllItems]);
  useEffect(() => {
    const found = cartItems.find((eachItem) => eachItem.id === hit.id);

    if (found) {
      setQuantity(found.items_detail.quantity);
    } else {
      setQuantity(0);
    }
  }, [cartItems, hit.id]);

  const DisplayStatus = (status, selling_price) => {
    if (status === "active") {
      if (typeof selling_price === "number") {
        if (selling_price === 0) {
          return {
            selling_price: t("home.willBePriced"),
            color: "secondary.main",
            disapled: false,
          };
        } else {
          return {
            selling_price: selling_price + " " + t("currency.SAR"),
            color: "primary.main",
            disapled: false,
          };
        }
      } else if (selling_price === "") {
        return {
          selling_price: t("home.willBePriced"),
          color: "secondary.main",
          disapled: false,
        };
      }
    } else if (status === "inactive") {
      return {
        selling_price: t("home.notAvailable"),
        color: "primary.main",
        disapled: true,
      };
    } else if (status === "out_of_stock") {
      return {
        selling_price: t("home.outOfStock"),
        color: "primary.main",
        disapled: true,
      };
    }
  };

  //SECTION - FUNCTIONS
  const { selling_price, color, disapled } = DisplayStatus(
    hit.status,
    hit.selling_price
  );
  const opacity = disapled ? 0.5 : 1;
  const pointerEvents = disapled ? "none" : "";
  const requiresTruncation = isMobileScreen
    ? hit.description?.length > 10
    : false;
  const truncatedDescription = isMobileScreen
    ? requiresTruncation
      ? hit.description?.substring(0, 8) + "..." // Truncate the description
      : hit.description
    : requiresTruncation
    ? hit.description?.substring(0, 30) + "..." // Truncate the description
    : hit.description;

  const handleChange = (e) => {
    if (e.target.value === "") {
      setQuantity(0);
    } else if (isNaN(e.target.value)) {
      e.preventDefault();
    } else if (parseInt(digitsArToEn(e.target.value)) > 999999) {
      setQuantity((prev) => prev);
    } else {
      setQuantity(parseInt(digitsArToEn(e.target.value)));
      setItem({
        id: hit.id,
        quantity: digitsArToEn(e.target.value),
      });
    }
  };
  return (
    <>
      {!disapled && (
        <Grid
          xs={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            border: `2px solid ${theme.palette.gray.g200}`,
            borderRadius: theme.borderRadius.r15,
            padding: "0.6rem",
            opacity,
            pointerEvents,
            "&:hover": {
              border: `2px solid ${theme.palette.secondary.main} `,
              backgroundColor: ` ${theme.palette.gray.g25}`,
            },
            // maxHeight: "80px",
          }}
        >
          <Grid
            xs={1}
            sm={1}
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
            textAlign={"center"}
            alignContent={"center"}
          >
            <img
              style={{
                width: "35px",
                height: "35px",
                objectFit: "contain",
              }}
              src={FindBrandLogo(brands, hit.manufacturer.id)}
              alt={hit.manufacturer.name_en}
            />
            <Typography
              fontSize={{
                xs: "0.5rem",
                semiMd: "0.6rem",
                lg: "0.7rem",
              }}
              sx={{
                color: theme.palette.gray.g500,
                fontWeight: theme.typography.bold,
                // whiteSpace: "nowrap",
              }}
            >
              {hit.brand.name_en}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            xs={2.5}
            sm={4}
            sx={{ paddingX: isMobileScreen ? "0.7rem" : "1rem" }}
          >
            <Grid
              xs={12}
              container
              alignItems={"flex-start"}
              direction={"column"}
              justifyContent={"center"}
            >
              <Typography
                dir="ltr"
                fontWeight={theme.typography.bold}
                whiteSpace={"nowrap"}
                fontSize={{
                  xs: "0.8rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                }}
              >
                {hit.part_number}
              </Typography>
              <Tooltip
                title={requiresTruncation ? hit.description : ""}
                open={openToolTip}
                onClick={() => setOpenToolTip(true)}
                onClose={() => setOpenToolTip(false)}
                onMouseEnter={() => setOpenToolTip(true)}
                onMouseLeave={() => setOpenToolTip(false)}
              >
                <Typography
                  fontWeight={theme.typography.bold}
                  whiteSpace={"nowrap"}
                  fontSize={{
                    xs: "0.8rem",
                    sm: "0.9rem",
                  }}
                  color="secondary.main"
                >
                  {truncatedDescription}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            xs={8.4}
            sm={7}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            direction={"row"}
          >
            <Grid
              container
              xs={12}
              justifyContent={"flex-end"}
              alignItems={"center"}
              direction={"row"}
              gap={theme.spacing(0.5)}
            >
              <WillBePricedWrapper
                selling_price={selling_price}
                Child={() => (
                  <Typography
                    fontSize={{
                      xs: "0.7rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                    }}
                    fontWeight={theme.typography.bold}
                    color={color}
                    marginInlineEnd={3}
                  >
                    {selling_price}
                  </Typography>
                )}
                id={hit.part_number}
              />

              <Button
                color="tertiary"
                disabled={quantity === 0}
                onClick={() => {
                  if (quantity > 0) {
                    setQuantity((prevQuantity) => prevQuantity - 1);
                    setItem({
                      id: hit.id,
                      quantity: quantity - 1,
                    });
                  }
                }}
                sx={{
                  padding: "0px",
                  minWidth: "initial",
                  borderRadius: "0.5rem",
                  border: `1px solid ${theme.palette.gray.g200}`,
                  "&:hover": {
                    backgroundColor: theme.palette.gray.g100,
                    border: `1px solid ${theme.palette.gray.g400}`,
                  },
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "fit-content", md: "40px" },
                  height: "40px",
                  opacity: quantity === 0 && 0.5,
                }}
              >
                <RemoveIcon sx={{ color: "black" }} />
              </Button>
              <Input
                value={quantity}
                disableUnderline
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "0px",
                    height: "40px",
                  },
                  onKeyPress: ValidateInputOnlyNum, // only enter numbers, no chars
                }}
                sx={{
                  maxWidth: "4rem",
                  fontWeight: theme.typography.bold,
                  border: `1px solid ${theme.palette.gray.g200}`,
                  borderRadius: "0.5rem",
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "fit-content", md: "60px" },
                  height: "40px",
                  padding: "0px",
                }}
                onClick={(e) => e.target.select()}
                onChange={(e) => handleChange(e)}
                onPaste={(e) => {
                  e.preventDefault();
                }}
              />
              <Button
                color="tertiary"
                disabled={quantity === 999999}
                onClick={() => {
                  setQuantity((prevQuantity) => prevQuantity + 1);
                  setItem({
                    id: hit.id,
                    quantity: quantity + 1,
                  });
                }}
                sx={{
                  padding: "0px",
                  minWidth: "initial",
                  borderRadius: "0.5rem",
                  border: `1px solid ${theme.palette.gray.g200}`,
                  "&:hover": {
                    backgroundColor: theme.palette.gray.g100,
                    border: `1px solid ${theme.palette.gray.g400}`,
                  },
                  backgroundColor: theme.palette.gray.g50,
                  width: { xs: "fit-contet", md: "40px" },
                  height: "40px",
                  opacity: quantity === 999999 && 0.5,
                }}
              >
                <AddIcon sx={{ color: "black" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ItemHit;

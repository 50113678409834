import {
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrdersRequiringConfirmation,
  closeNotification,
  getNotificationDisplayStatus,
} from "../../app/features/order/order";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useEffect } from "react";
import { isAuthenticated } from "../../utils/api/Authentication";

const OrdersNotification = () => {
  // SECTION - general
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLanguae = localStorage.getItem("lng");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("semiSm"));

  // SECTION - useSelector
  const unconfirmedOrders = useSelector(getOrdersRequiringConfirmation);
  const displayStatus = useSelector(getNotificationDisplayStatus);

  //SECTION - useEffect
  useEffect(() => {}, [location]);

  return (
    isAuthenticated() &&
    unconfirmedOrders !== null &&
    unconfirmedOrders.length !== 0 &&
    location.pathname !== "/orders" &&
    displayStatus && (
      <Grid
        container
        bgcolor={theme.palette.primary.main}
        color={"white"}
        justifyContent="center"
        alignItems="center"
        textAlign={"center"}
        padding={theme.spacing(0.5)}
        gap={{ xs: theme.spacing(1) }}
        direction={isMobileScreen ? "column" : "row"}
      >
        <Typography fontSize={{ xs: "0.7rem", semiSm: "0.8rem", sm: "1rem" }}>
          {unconfirmedOrders.length === 1
            ? t("order.confirmOneOrder")
            : t("order.confirmManyOrders", {
                ordersNumber: unconfirmedOrders.length,
              })}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/orders");
          }}
          endIcon={
            currentLanguae === "ar" ? (
              <IoArrowBack
                style={{
                  marginRight: theme.spacing(1),
                }}
              />
            ) : (
              <IoArrowForward />
            )
          }
          sx={{
            color: "white",
            bgcolor: theme.palette.primary.dark,
            borderRadius: "25px",
            maxHeight: "25px",
            fontSize: {
              xs: "0.7rem",
              semiSm: "0.8rem",
              sm: "1rem",
            },
            textTransform: "none",
            paddingX: {
              xs: theme.spacing(1),
              sm: theme.spacing(2),
            },
            marginX: { xs: theme.spacing(2), semiSm: "0px" },
            paddingY: theme.spacing(0.5),
          }}
        >
          {t("order.goOrders")}
        </Button>
        <CloseOutlined
          size={24}
          onClick={() => dispatch(closeNotification())}
          color={theme.palette.text.primary}
          sx={{
            position: "absolute",
            [i18n.language !== "ar" ? "right" : "left"]: theme.spacing(1),
            cursor: "pointer",
          }}
        />
      </Grid>
    )
  );
};
export default OrdersNotification;

import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ traceXHR: true }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions, adjust in production
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/herokuapp\.com\/api/,
      /^https:\/\/autobia\.com\/api/,
    ],
    replaysSessionSampleRate:
      process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0, // Sample 10% of sessions, adjust in production
    replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
    environment: process.env.REACT_APP_ENV || "development", // Set environment here
  });
};

export const captureException = (error, context = {}) => {
  Sentry.captureException(error, { extra: context });
};

export const setUserContext = (userId, attributes) => {
  Sentry.setUser({ id: userId, ...attributes });
};

import i18next from "i18next";
import IsEmpty from "../validation/IsEmpty";
const CalculateCartPrice = (
    totalOrders,
    pricingConfig,
    items,
    couponObj,
    constantDiscount,
    isCoupApplied
) => {
    // Extract coupon details

    const {
        discount_thresholds,
        max_discount_limit,
        coupon_type,
        is_incremental,
        limit_to_apply,
    } = couponObj;
    const {
        shipping_price,
        shipping_price_apply_below,
        total_orders_free_shipping,
        vat_percentage,
    } = pricingConfig;
    //Any discounts over the coupon/cashback. (e.g compensations, etc..)

    let price = 0;
    let priced = true;

    // Calculate the total price of all items in the cart
    for (let i = 0; i < items.length; i++) {
        if (items[i].items_detail.selling_price === 0) {
            // If an item's selling price is zero, it is not priced
            priced = false;
        } else {
            // Add the selling price multiplied by the quantity to the total price
            price +=
                items[i].items_detail.selling_price *
                items[i].items_detail.quantity;
        }
    }

    if (priced) {
        if (isCoupApplied === true) {
            //Parsing & Initializations
            const constDiscount = parseFloat(constantDiscount);
            const discountPercentage = calculateDiscountPercentage(
                discount_thresholds,
                price
            );
            const maxAllowedCoupPrice = parseFloat(max_discount_limit);
            let isUserNew = false;
            let shippingPrice = parseFloat(
                price >= parseFloat(shipping_price_apply_below) || price === 0
                    ? 0
                    : parseFloat(shipping_price)
            );
            let totalPrice = parseFloat(price.toFixed(2));
            let cashback = 0;
            let VAT = 0;

            //if user is NEW, meaning total number of orders a user have is less than the allowed number of orders of free shipping
            if (
                !IsEmpty(totalOrders) &&
                !IsEmpty(total_orders_free_shipping) &&
                totalOrders < total_orders_free_shipping
            ) {
                shippingPrice = 0;
                isUserNew = true;
            }

            //difference in price without shipping and VAT minus the constant discount
            const differenceInPrice = price - constDiscount;

            //Calculate discount/cashback to the total price without shipping and VAT and with constantDiscount
            let discountValue = (discountPercentage / 100) * differenceInPrice;

            // If the value of coupon is greater than max_discount_limit allowed for coupon to have,then let it max_discount_limit, else discountValue
            discountValue =
                discountValue > maxAllowedCoupPrice
                    ? maxAllowedCoupPrice
                    : discountValue;

            //If it coupon type is discount then deduct from the total price without shipping and VAT
            if (coupon_type === "discount") {
                // Apply discount to the total price without shipping and VAT
                discountValue += constDiscount;
                totalPrice -= parseFloat(discountValue);
                VAT = parseFloat(
                    (
                        (parseFloat(vat_percentage) / 100) *
                        (totalPrice + shippingPrice)
                    ).toFixed(2)
                );
            } else if (coupon_type === "cashback") {
                cashback = parseFloat(discountValue);
                discountValue = constDiscount;
                totalPrice -= parseFloat(discountValue);
                VAT = parseFloat(
                    (
                        (parseFloat(vat_percentage) / 100) *
                        (totalPrice + shippingPrice)
                    ).toFixed(2)
                );
            }

            // Add the shipping and VAT prices
            totalPrice = totalPrice + shippingPrice + VAT;

            return {
                price: `${price.toFixed(2)} ${i18next.t("currency.SAR")}`,
                shippingPrice:
                    price >= parseFloat(shipping_price_apply_below) ||
                    isUserNew === true
                        ? i18next.t("cart.free")
                        : `${shippingPrice} ${i18next.t("currency.SAR")}`,
                couponType: coupon_type,
                cashback: `${cashback.toFixed(2)} ${i18next.t(
                    "currency.SAR"
                )} ${
                    price < limit_to_apply
                        ? i18next.t("cart.minCouponValue", {
                              value: limit_to_apply,
                              currency: i18next.t("currency.SAR"),
                          })
                        : ""
                }`,
                discountValue:
                    isCoupApplied === false
                        ? "-"
                        : `${discountValue.toFixed(2)} ${i18next.t(
                              "currency.SAR"
                          )} ${
                              price < limit_to_apply
                                  ? i18next.t("cart.minCouponValue", {
                                        value: limit_to_apply,
                                        currency: i18next.t("currency.SAR"),
                                    })
                                  : ""
                          }`,
                VAT: `${VAT} ${i18next.t("currency.SAR")}`,
                totalPrice: `${totalPrice.toFixed(2)} ${i18next.t(
                    "currency.SAR"
                )}`,
                isUserNew,
                isShippingFree:
                    price >= parseFloat(shipping_price_apply_below) ||
                    isUserNew === true,
            };
        } else {
            let totalPrice = parseFloat(price.toFixed(2));

            const VAT = parseFloat((0.15 * totalPrice).toFixed(2));
            let isUserNew = false;
            let shippingPrice = parseFloat(
                price >= parseFloat(shipping_price_apply_below) || price === 0
                    ? 0
                    : parseFloat(shipping_price)
            );

            //if user is NEW, meaning total number of orders a user have is less than the allowed number of orders of free shipping
            if (
                !IsEmpty(totalOrders) &&
                !IsEmpty(total_orders_free_shipping) &&
                totalOrders < total_orders_free_shipping
            ) {
                shippingPrice = 0;
                isUserNew = true;
            }

            // Add the shipping and VAT prices
            totalPrice = totalPrice + shippingPrice + VAT;

            return {
                price: `${price.toFixed(2)} ${i18next.t("currency.SAR")}`,
                shippingPrice:
                    price >= parseFloat(shipping_price_apply_below) ||
                    isUserNew === true
                        ? i18next.t("cart.free")
                        : `${shippingPrice} ${i18next.t("currency.SAR")}`,
                couponType: coupon_type,
                discountValue: "-",
                VAT: `${VAT} ${i18next.t("currency.SAR")}`,
                totalPrice: `${totalPrice.toFixed(2)} ${i18next.t(
                    "currency.SAR"
                )}`,
                isUserNew,
                isShippingFree:
                    price >= parseFloat(shipping_price_apply_below) ||
                    isUserNew === true,
            };
        }
    } else {
        // Return placeholder values for price, shipping, VAT, and total price
        let discountPercentage = 0;

        if (!IsEmpty(discount_thresholds)) {
            discountPercentage = calculateDiscountPercentage(
                discount_thresholds,
                price
            );
        }

        //if coupon is not incremental
        if (
            !is_incremental &&
            discount_thresholds &&
            discount_thresholds.length > 0
        ) {
            discountPercentage = discount_thresholds[0]?.percentage;
        }

        return {
            price: "-",
            shippingPrice: "-",
            cashback: `${i18next.t("cart.maxCouponValue", {
                maxPrice: max_discount_limit,
                discount: discountPercentage,
            })} ${i18next.t("currency.SAR")}`,
            couponType: coupon_type,
            discountValue:
                isCoupApplied && coupon_type === "discount"
                    ? `${i18next.t("cart.maxCouponValue", {
                          maxPrice: max_discount_limit,
                          discount: discountPercentage,
                      })} ${i18next.t("currency.SAR")}`
                    : "-",

            VAT: "-",
            totalPrice: "-",
            isUserNew: false,
            isWillBePriced: true,
        };
    }
};

const calculateDiscountPercentage = (discountAndThresholds, price) => {
    let discountPercentage = 0;

    // Create a copy of the discountAndThresholds array
    const sortedThresholds = [...discountAndThresholds];

    // Sort the copy of the array by threshold value in descending order
    sortedThresholds.sort((a, b) => b.threshold - a.threshold);

    for (const threshold of sortedThresholds) {
        if (price >= threshold.threshold) {
            discountPercentage = threshold.percentage;
            break; // Break out of the loop once the first applicable threshold is found
        }
    }

    return discountPercentage;
};

export default CalculateCartPrice;

import HeroSection from "./HeroSection";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import { Box, LinearProgress } from "@mui/material";
import CustomSearchBox from "./Algolia/CustomSearchBox";
import HomeTable from "./Algolia/HomeTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartItems,
  getFetchCartStatus,
  getPutCartStatus,
} from "../../app/features/cart/cart";
import { fetchBrands } from "../../app/features/brand/brand";
import { fetchOrders } from "../../app/features/order/order";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import { InstantSearch, Configure } from "react-instantsearch";

//ANCHOR - Algolia keys
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_PUBLIC_SEARCH_API_KEY
);

const Home = () => {
  //SECTION - GENERAL
  const dispatch = useDispatch();

  //SECTION - Selectors
  const fetchCartStatus = useSelector(getFetchCartStatus);
  const cartLoading = useSelector(getPutCartStatus);

  //SECTION - useEffects
  useEffect(() => {
    dispatch(fetchCartItems());
    dispatch(fetchBrands());
    dispatch(fetchOrders());
  }, [dispatch]);

  return (
    <InstantSearch
      indexName={process.env.REACT_APP_ALGOLIA_INDEX}
      searchClient={searchClient}
    >
      <Box position={"relative"}>
        {fetchCartStatus !== "loading" ? (
          <>
            <LoadingOverlay isLoading={cartLoading === "loading"} />
            <HeroSection />
            <CustomSearchBox />
            <HomeTable />
            <Configure filters={"status:active"} />
          </>
        ) : (
          <Box width={"100%"}>
            <LinearProgress color="primary" />
          </Box>
        )}
      </Box>
    </InstantSearch>
  );
};

export default Home;

import i18next from "i18next";

export const processingStatuses = [
  "pending_approval",
  "pending_payment",
  "processing_payment",
  "processing",
  "pending",
];

export const completedStatuses = ["completed", "approved"];

export const filterQueryStatuses = [
  { id: 1, label: "all" },
  { id: 2, label: "pending_approval" },
  { id: 3, label: "pending_payment" },
  { id: 4, label: "processing_payment" },
  { id: 5, label: "processing" },
  { id: 6, label: "completed" },
  { id: 7, label: "declined" },
  { id: 8, label: "customer_declined" },
  { id: 9, label: "unknown" },
  { id: 10, label: "failed" },
  { id: 11, label: "expired" },
  { id: 12, label: "transaction_creation_failed" },
  { id: 13, label: "cancelled" },
];

export const filterQueryTypes = [
  { id: 1, label: "all" },
  { id: 2, label: "Payment" },
  { id: 3, label: "Refund" },
  { id: 4, label: "Withdraw" },
  { id: 5, label: "Service Fee" },
  { id: 6, label: "Credit Note" },
  { id: 7, label: "Reverse Payment Operation" },
  { id: 8, label: "Reverse Refund Operation" },
  { id: 9, label: "Reverse Withdraw Operation" },
  { id: 10, label: "Reverse Service Fee Operation" },
  { id: 11, label: "Reverse Credit Note Operation" },
];

export const filterQuerySettlements = [
  { id: 1, label: "all" },
  { id: 2, label: "completed", shownLabel: "settlementCompleted" },
  { id: 3, label: "pending" },
];
export const filterQueryDocuments = [
  { id: 1, label: "all" },
  { id: 2, label: "tax_invoice" },
  { id: 3, label: "credit_note" },
  { id: 4, label: "purchase_approval" },
  { id: 5, label: "settlement" },
];

export const offer_object = {
  id: 628,
  title_en: "Unlimiteed Discount",
  title_ar: "خصم بدون حدود",
  description_en:
    "Your order, your discount!✨ The more you buy, the more you save. Terms and conditions apply: - Use your installment wallet balance. - Minimum purchase of 15,000 required. Limited time offer!🏃",
  description_ar:
    "عرض خاص لك! خصم يزداد مع زيادة طلبك✨ الشروط: - استخدام رصيد محفظة التقسيط. - الحد الأدنى للطلب 15,000 الحق العرض لفترة محدوده 🏃",
  button_en: "  ",
  button_ar: "  ",
  expiration_at: "2024-09-14T11:59:00+03:00",
  action: [
    {
      app: 3,
      type: "null",
    },
  ],
  filer: {
    file:
      i18next.dir() === "rtl"
        ? "https://deals-uploads.s3.amazonaws.com/media/uploads/filer/image/2024/09/08/filer_image_84afea11-1508-4e42-813e-240ff010504a.jpeg"
        : "https://deals-uploads.s3.amazonaws.com/media/uploads/filer/image/2024/09/08/filer_image_93466b15-413c-46aa-820a-abe06eb90528.jpeg",
  },
  created_at: "2024-08-12T11:57:59.622135+03:00",
};

export const getBNPLOrdersTableColumns = () => {
  return [
    {
      id: "logo",
      label: "",
      align: "center",
    },
    {
      id: "orderID",
      label: i18next.t("wallet.orderID"),
      align: "center",
    },
    {
      id: "clientName",
      label: i18next.t("wallet.clientName"),
      align: "center",
    },
    {
      id: "clientNumber",
      label: i18next.t("wallet.clientNumber"),
      align: "center",
    },
    {
      id: "price",
      label: i18next.t("wallet.price"),
      align: "center",
    },
    // {
    //   id: "priceAfterFees",
    //   label: t("wallet.priceAfterFees"),
    //   align: "center",
    // },
    {
      id: "transactionDate",
      label: i18next.t("wallet.transactionDate"),
      align: "center",
    },
    {
      id: "note",
      label: i18next.t("wallet.noteColumn"),
      align: "center",
    },

    {
      id: "status",
      label: i18next.t("wallet.status"),
      align: "center",
    },
    {
      id: "action",
      label: i18next.t("wallet.action"),
      align: "center",
    },
  ];
};
export const getBNPLTransactionsTableColumns = () => {
  return [
    {
      id: "logo",
      label: "",
      align: "center",
    },
    {
      id: "orderID",
      label: i18next.t("wallet.orderID"),
      align: "center",
    },
    {
      id: "clientName",
      label: i18next.t("wallet.clientName"),
      align: "center",
    },
    {
      id: "clientNumber",
      label: i18next.t("wallet.clientNumber"),
      align: "center",
    },
    {
      id: "price",
      label: i18next.t("wallet.price"),
      align: "center",
    },
    // {
    //   id: "priceAfterFees",
    //   label: t("wallet.priceAfterFees"),
    //   align: "center",
    // },
    {
      id: "transactionDate",
      label: i18next.t("wallet.transactionDate"),
      align: "center",
    },
    // {
    //   id: "note",
    //   label: t("wallet.noteColumn"),
    //   align: "center",
    // },
    // {
    //   id: "editNote",
    //   label: "",
    //   align: "center",
    // },
    {
      id: "balance",
      label: i18next.t("wallet.balanceAfterTransactions"),
      align: "center",
    },
    {
      id: "type",
      label: i18next.t("wallet.type"),
      align: "center",
    },
    // {
    //   id: "action",
    //   label: t("wallet.action"),
    //   align: "center",
    // },
  ];
};
export const getBNPLSettlementTableColumns = () => {
  return [
    {
      id: "settlement_id",
      label: i18next.t("wallet.settlementData.settlementID"),
      align: "center",
    },
    {
      id: "current_settlement_date",
      label: i18next.t("wallet.settlementData.settlementDate"),
      align: "center",
    },

    {
      id: "sales_amount",
      label: i18next.t("wallet.settlementData.salesAmount"),
      align: "center",
    },
    {
      id: "refunds_amount",
      label: i18next.t("wallet.settlementData.refundsAmount"),
      align: "center",
    },
    {
      id: "service_fees_amount",
      label: i18next.t("wallet.settlementData.serviceFeesAmount"),
      align: "center",
    },
    {
      id: "vat_transaction_amount",
      label: i18next.t("wallet.settlementData.vatTransactionAmount"),
      align: "center",
    },
    {
      id: "purchases_amount",
      label: i18next.t("wallet.settlementData.purchasesAmount"),
      align: "center",
    },
    {
      id: "withdraw_amount",
      label: i18next.t("wallet.settlementData.netAmount"),
      align: "center",
    },
    {
      id: "settlementStatus",
      label: i18next.t("wallet.settlementData.status"),
      align: "center",
    },
    {
      id: "transfer_file_url",
      label: i18next.t("wallet.settlementData.downloadLink"),
      align: "center",
    },
  ];
};
export const getBNPLDocumentsTableColumns = () => {
  return [
    {
      id: "created_at",
      label: i18next.t("wallet.documentsData.created_at"),
      align: "center",
    },
    {
      id: "name",
      label: i18next.t("wallet.documentsData.name"),
      align: "center",
    },
    {
      id: "documentType",
      label: i18next.t("wallet.documentsData.type"),
      align: "center",
    },
    {
      id: "actions",
      label: i18next.t("wallet.documentsData.downloadFiles"),
      align: "center",
    },
  ];
};
